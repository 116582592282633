<template>
  <div id="manualPassingDialog">
    <el-dialog
      title="模拟过车"
      :visible.sync="show"
      :close-on-press-escape="false"
      @closed="reset"
    >
      <el-form :model="form" :rules="rules" ref="form" label-width="100px">
        <el-form-item label="是否开闸" prop="open">
          <el-radio-group v-model="form.open">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="车牌号" prop="plateNum">
          <el-input
            v-model="form.plateNum"
            placeholder="请输入车牌号"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["getList"],
  data() {
    return {
      show: false,
      loading: false,
      channelId: "",
      form: {
        open: 1,
        plateNum: "",
      },
      rules: {
        open: [
          { required: true, message: "请选择出入口类型", trigger: "change" },
        ],
        plateNum: [
          { required: true, message: "请输入车牌号", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    reset() {
      this.$set(this, "form", {
        open: 1,
        plateNum: "",
      });
      this.$refs["form"].resetFields();
    },
    confirmBtn() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            let res = await this.$http.post("/dahua/device/manual/passing", {
              ...this.form,
              channelId: this.channelId,
            });

            if (res.code === 0) {
              this.$message.success("操作成功");
              this.show = false;
            }
          } catch (err) {
            this.$message.error(err);
          } finally {
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss">
#manualPassingDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 400px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .el-select {
          width: 100%;
        }
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
            margin-right: 50px;
          }
        }
      }
    }
  }
}
</style>
